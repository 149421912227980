"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SDK_VERSION_REGEXP = exports.WEBAPP_URL_CONFIG = exports.SDK_URL = exports.TOKEN_ENV = void 0;
exports.TOKEN_ENV = {
    LOCAL: "local",
    DEV: "development",
    STG: "staging",
    SBX: "sandbox",
    BETA: "beta",
    PROD: "production",
};
exports.SDK_URL = "https://app-sdk.token.io";
exports.WEBAPP_URL_CONFIG = {
    local: "http://localhost:5000",
    development: "https://app.dev.token.io",
    staging: "https://app.stg.token.io",
    sandbox: "https://app.sandbox.token.io",
    beta: "https://app.beta.token.io",
    production: "https://app.token.io",
};
exports.SDK_VERSION_REGEXP = /https?:\/\/app-sdk\.token\.io\/(.*)\/token\.min\.js/;
