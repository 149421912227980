"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenApp = void 0;
var token_1 = require("constants/token");
var TokenController_1 = require("./TokenController");
var TokenManager_1 = require("./TokenManager");
var TokenApp = /** @class */ (function () {
    function TokenApp(_a) {
        var _this = this;
        var env = _a.env;
        this.createController = function (options) {
            return new TokenController_1.TokenController(__assign(__assign({}, options), { url: _this.appSdkUrl, appUrl: _this.appUrl, sdkVersion: _this.sdkVersion }));
        };
        this.buildController = function (options) {
            return new TokenManager_1.TokenManager(__assign(__assign({}, options), { url: _this.appSdkUrl, appUrl: _this.appUrl, sdkVersion: _this.sdkVersion }));
        };
        this.env = env;
        this.appSdkUrl = token_1.SDK_URL;
        this.appUrl = token_1.WEBAPP_URL_CONFIG[env];
        if (!this.appUrl)
            throw new Error("Token Env is invalid");
        var src = document.querySelector('script[src*="token.min.js"]').getAttribute('src');
        var match = src.match(token_1.SDK_VERSION_REGEXP);
        if (match && match[1]) {
            this.sdkVersion = match[1];
        }
        else {
            throw new Error("SDK version is invalid");
        }
    }
    return TokenApp;
}());
exports.TokenApp = TokenApp;
