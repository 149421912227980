"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenModalController = void 0;
var BaseIframeController_1 = require("./BaseIframeController");
var popup_1 = require("constants/popup");
var popup_2 = require("popup");
var modal_1 = require("constants/modal");
var MediaQueryManager_1 = require("common/MediaQueryManager");
var TokenModalController = /** @class */ (function (_super) {
    __extends(TokenModalController, _super);
    function TokenModalController(_a) {
        var _this = this;
        var url = _a.url;
        var mediaQueryManager = new MediaQueryManager_1.MediaQueryManager();
        var appearanceMode = mediaQueryManager.getPreferredColorScheme();
        var backgroundColor = appearanceMode === modal_1.APPEARANCE_MODES.DARK ? modal_1.BG_COLOR.DARK : modal_1.BG_COLOR.LIGHT;
        var src = url + "/loading";
        _this = _super.call(this, {
            src: src,
            name: "token-modal-iframe",
            attrs: {
                style: {
                    position: "static",
                    display: "inline",
                    height: "650px",
                    maxWidth: "700px",
                    zIndex: "999999",
                    right: "0",
                    bottom: "0",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: backgroundColor,
                    borderRadius: '4px'
                },
            },
        }) || this;
        _this._bindEvents = function () {
            var messenger = _this._getMessengerInstance();
            messenger.onMessage(popup_1.POPUP_EVENTS.BANK_POPUP_SHOW, function (_a) {
                var url = _a.url;
                new popup_2.TokenBankPopup({ url: url });
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE, function () {
                if (_this._iframe) {
                    _this.destroyModal();
                }
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE_SUCCESS, function () {
                if (_this._iframe) {
                    _this.destroyModal();
                }
            }, true);
            messenger.onMessage(modal_1.MODAL_EVENTS.MODAL_CLOSE_FAILURE, function () {
                if (_this._iframe) {
                    _this.destroyModal();
                }
            }, true);
            messenger.onMessage(popup_1.POPUP_EVENTS.BANK_POPUP_VISIBILITY_CHANGE, function (_a) {
                var visible = _a.visible;
                if (_this._iframe && !visible) {
                    _this.destroyModal();
                }
            }, true);
        };
        _this._handleResize = function () {
            if (_this._iframe) {
                if (window.innerWidth > 700) {
                    _this._iframe.style.width = '750px';
                }
                else {
                    _this._iframe.style.width = '80%';
                }
            }
        };
        _this._bindEvents();
        _this._handleResize();
        window.addEventListener('resize', _this._handleResize);
        return _this;
    }
    TokenModalController.prototype.destroyModal = function () {
        window.removeEventListener('resize', this._handleResize);
        this.destroy();
    };
    return TokenModalController;
}(BaseIframeController_1.BaseIframeController));
exports.TokenModalController = TokenModalController;
