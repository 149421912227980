"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Messenger = void 0;
var Messenger = /** @class */ (function () {
    function Messenger(_a) {
        var _this = this;
        var _b = _a.id, id = _b === void 0 ? "" : _b, _c = _a.origin, origin = _c === void 0 ? null : _c, source = _a.source, destination = _a.destination;
        this._messages = [];
        this._bindListeners = function () {
            _this._source.addEventListener("message", _this._onReceiveMessage);
        };
        this._unbindListeners = function () {
            _this._source.removeEventListener("message", _this._onReceiveMessage);
        };
        this._onReceiveMessage = function (e) {
            var data = e.data;
            if (!data)
                return;
            try {
                data = _this._decodeMessage(data);
            }
            catch (e) {
                return;
            }
            var type = data.type;
            if (!type)
                return;
            var m = _this._messages.find(function (m) { return m.type === type; });
            if (!m)
                return;
            // is weak reference
            if (m.weak) {
                _this._id = data.id;
            }
            else {
                // is strong reference
                if (data.id !== _this._id) {
                    return;
                }
            }
            // execute callback
            m.callback(data);
        };
        this.onMessage = function (type, callback, weak) {
            if (_this._messages.length && _this._messages.find(function (m) { return m.type === type; })) {
                return;
            }
            var w = weak ? weak : false;
            _this._messages.push({
                type: type,
                callback: callback,
                weak: w,
            });
            return _this;
        };
        this.destroy = function () {
            _this._unbindListeners();
            _this._destination = null;
            _this._source = null;
            _this._origin = "*";
            _this._messages = [];
        };
        this._id = id;
        this._origin = origin;
        this._source = source;
        this._destination = destination;
        if (this._source) {
            this._bindListeners();
        }
    }
    Messenger.prototype.dispatchMessage = function (message, destination, origin) {
        var d = destination || this._destination;
        var o = origin || this._origin || "*";
        var m = this._decodeMessage(message);
        if (this._id) {
            m.popupId = this._id;
        }
        d.postMessage(this._encodeMessage(m), o);
        return this;
    };
    Messenger.prototype._decodeMessage = function (message) {
        if (typeof message === "object") {
            return message;
        }
        if (typeof message === "string" && message.length) {
            try {
                return JSON.parse(message);
            }
            catch (e) {
                return {
                    type: message,
                };
            }
        }
        throw new Error("INVALID_MESSAGE");
    };
    Messenger.prototype._encodeMessage = function (message) {
        if (typeof message === "string") {
            return message;
        }
        if (typeof message === "object") {
            try {
                return JSON.stringify(message);
            }
            catch (e) {
                throw new Error("INVALID_MESSAGE");
            }
        }
        throw new Error("INVALID_MESSAGE");
    };
    return Messenger;
}());
exports.Messenger = Messenger;
