"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BG_COLOR = exports.APPEARANCE_MODES = exports.MODAL_EVENTS = void 0;
exports.MODAL_EVENTS = {
    MODAL_CLOSE: 'MODAL_CLOSE',
    MODAL_CLOSE_SUCCESS: 'MODAL_CLOSE_SUCCESS',
    MODAL_CLOSE_FAILURE: 'MODAL_CLOSE_FAILURE',
    SET_MODAL_STYLE: 'SET_MODAL_STYLE'
};
exports.APPEARANCE_MODES = {
    DARK: 'DARK',
    LIGHT: 'LIGHT'
};
exports.BG_COLOR = {
    DARK: "#121212",
    LIGHT: "white"
};
