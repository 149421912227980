"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
var Utils = /** @class */ (function () {
    function Utils() {
    }
    Utils.generateNonce = function () {
        return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
    };
    Utils.isFunction = function (v) { return typeof v === 'function'; };
    return Utils;
}());
exports.Utils = Utils;
