"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseIframeController = void 0;
var Dom_1 = require("common/Dom");
var Messenger_1 = require("common/Messenger");
var Utils_1 = require("common/Utils");
var iframe_1 = require("constants/iframe");
var BaseIframeController = /** @class */ (function () {
    function BaseIframeController(_a) {
        var _this = this;
        var src = _a.src, name = _a.name, _b = _a.attrs, attrs = _b === void 0 ? {} : _b;
        this._id = Utils_1.Utils.generateNonce();
        this._containerElement = window.document.body;
        this.show = function () {
            var style = _this._attrs.style;
            style.position = "fixed";
            style.display = "block";
            Dom_1.Dom.applyStyle(_this._iframe, _this._attrs.style);
        };
        this.destroy = function () {
            _this._unbindListeners();
            _this._clearContent();
            _this._iframe = null;
        };
        this.updateAttrs = function (attrs) {
            var src = attrs.src, height = attrs.height, width = attrs.width;
            var iframeAttributes = {};
            if (src) {
                iframeAttributes['src'] = src;
            }
            Dom_1.Dom.applyAttrs(_this._iframe, iframeAttributes);
            if (height || width) {
                var iframeStyles = {};
                if (height) {
                    iframeStyles['height'] = height;
                }
                if (width) {
                    iframeStyles['max-width'] = width;
                }
                Dom_1.Dom.applyAttrs(_this._iframe, { style: iframeStyles });
            }
        };
        // Todo: find a better way
        this._clearContent = function () {
            var iframes = _this._containerElement.querySelectorAll("iframe[name=\"".concat(_this._name, "\"]"));
            iframes.forEach(function (iframe) {
                if (_this._containerElement.contains(iframe)) {
                    _this._containerElement.removeChild(iframe);
                }
            });
        };
        this._onIframeLoad = function () {
            var messenger = _this._getMessengerInstance();
            messenger.dispatchMessage({
                type: iframe_1.IFRAME_EVENTS.IFRAME_INIT,
            });
        };
        this._bindListeners = function () {
            if (!_this._iframeLoaded())
                return;
            _this._iframe.addEventListener("load", _this._onIframeLoad);
        };
        this._unbindListeners = function () {
            var _a;
            (_a = _this._iframe) === null || _a === void 0 ? void 0 : _a.removeEventListener("load", _this._onIframeLoad);
        };
        this._getMessengerInstance = function () {
            if (_this._messenger) {
                return _this._messenger;
            }
            var messenger = new Messenger_1.Messenger({
                id: _this._id,
                source: window,
                destination: _this._iframe.contentWindow,
            });
            return messenger;
        };
        this._src = src;
        this._name = name;
        this._attrs = __assign({ name: name, src: src, frameBorder: 0, width: "100%", height: "100%", style: {
                position: "static",
                display: "inline",
                zIndex: "1",
            } }, attrs);
        if (this._iframe) {
            return;
        }
        this._clearContent();
        this._iframe = Dom_1.Dom.createElement("iframe", this._attrs);
        this._containerElement.appendChild(this._iframe);
        this._bindListeners();
    }
    BaseIframeController.prototype._iframeLoaded = function () {
        return this._iframe instanceof window.Node;
    };
    return BaseIframeController;
}());
exports.BaseIframeController = BaseIframeController;
