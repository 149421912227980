"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dom = void 0;
var Dom = /** @class */ (function () {
    function Dom() {
    }
    Dom.applyStyle = function (el, style) {
        for (var key in style) {
            if (Object.prototype.hasOwnProperty.call(style, key)) {
                var value = style[key];
                if (value instanceof Array) {
                    for (var i = 0; i < value.length; i++) {
                        el.style[key] = value[i];
                    }
                }
                else {
                    el.style[key] = value;
                }
            }
        }
        return el;
    };
    Dom.applyAttrs = function (el, attrs, curr) {
        if (!el)
            return;
        if (el instanceof Window) {
            return Dom.applyWindowAttrs(el, attrs, curr);
        }
        for (var key in attrs) {
            if (Object.prototype.hasOwnProperty.call(attrs, key)) {
                switch (key) {
                    case "style":
                        Dom.applyStyle(el, attrs.style);
                        break;
                    case "className":
                        if (el.classList.contains(attrs.className))
                            break;
                        el.classList.add(attrs.className);
                        break;
                    default:
                        el.setAttribute(key, attrs[key]);
                }
            }
        }
        return true;
    };
    Dom.applyWindowAttrs = function (w, attrs, curr) {
        var _a = window.screen, availTop = _a.availTop, availHeight = _a.availHeight, availWidth = _a.availWidth, availLeft = _a.availLeft;
        var width = Math.round(attrs.width || curr.width);
        var height = Math.round(attrs.height || curr.height);
        var left = Math.round(attrs.left || curr.left);
        var top = Math.round(attrs.top || curr.top);
        var center = attrs.center ? attrs.center : false;
        if (center) {
            left = Math.round(curr.left + curr.width / 2 - width / 2);
            top = Math.round(curr.top + curr.height / 2 - height / 2);
        }
        if ((curr.left === left || curr.left === availLeft) &&
            (curr.top === top || curr.top === availTop) &&
            (curr.width === width || curr.width === availWidth) &&
            (curr.height === height || curr.height === availHeight)) {
            return true;
        }
        if (curr.left !== left || curr.top !== top) {
            w.moveTo(left, top);
        }
        if (curr.width !== width || curr.height !== height) {
            w.resizeTo(width, height);
        }
        return false;
    };
    Dom.createElement = function (type, attrs) {
        var el = window.document.createElement(type);
        Dom.applyAttrs(el, attrs);
        el.html = function (data) {
            if (typeof data === "string") {
                el.innerHTML = data;
                return el;
            }
            if (data instanceof HTMLElement) {
                el.appendChild(data);
                return el;
            }
            if (Array.isArray(data)) {
                el.innerHTML = "";
                for (var i = 0; i < data.length; i++) {
                    el.appendChild(data[i]);
                }
                return el;
            }
            return el;
        };
        el.text = function (data) {
            el.textContent = data;
            return el;
        };
        return el;
    };
    Dom.isAChildOf = function (_parent, _child) {
        if (_parent === _child) {
            return false;
        }
        while (_child && _child !== _parent) {
            _child = _child.parentNode;
        }
        return _child === _parent;
    };
    return Dom;
}());
exports.Dom = Dom;
