"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaQueryManager = void 0;
var modal_1 = require("constants/modal");
var MediaQueryManager = /** @class */ (function () {
    function MediaQueryManager() {
        var _this = this;
        this.mode = modal_1.APPEARANCE_MODES.LIGHT;
        this.setPreferredColorScheme = function (isDarkMode) {
            _this.mode = isDarkMode ? modal_1.APPEARANCE_MODES.DARK : modal_1.APPEARANCE_MODES.LIGHT;
        };
        this.getPreferredColorScheme = function () {
            return _this.mode;
        };
        var isMediaQuerySupported = 'matchMedia' in window;
        if (isMediaQuerySupported) {
            var darkModeListener = function (e) { return _this.setPreferredColorScheme(e.matches); };
            var matchMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            matchMediaQuery.addEventListener('change', darkModeListener);
            this.setPreferredColorScheme(matchMediaQuery.matches);
        }
    }
    return MediaQueryManager;
}());
exports.MediaQueryManager = MediaQueryManager;
