"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasePopup = void 0;
var Utils_1 = require("common/Utils");
var BasePopup = /** @class */ (function () {
    function BasePopup(_a) {
        var _this = this;
        var url = _a.url, name = _a.name;
        this._popupInitTimeout = 5000;
        this.popupExists = function () {
            if (!_this._popup)
                return false;
            return !_this._popup.closed;
        };
        this.getWindow = function () {
            return _this._popup;
        };
        this._waitUntilInitialized = function (popup) {
            var _popupInit = false;
            var startTime = new Date().getTime();
            var timePassed;
            while (!_popupInit) {
                if (popup && Utils_1.Utils.isFunction(popup.addEventListener)) {
                    _popupInit = true;
                }
                timePassed = new Date().getTime() - startTime;
                if (timePassed >= _this._popupInitTimeout) {
                    throw new Error("Popup initialization timeout");
                }
            }
        };
        this._createPopup = function () {
            var props = "location=yes,clearcache=yes";
            var popup = window.open(_this._url, _this._name, props);
            _this._waitUntilInitialized(popup);
            return popup;
        };
        this.destroy = function () {
            _this._popup.close();
        };
        this._url = url;
        this._name = name;
        this._popup = this._createPopup();
    }
    return BasePopup;
}());
exports.BasePopup = BasePopup;
