"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenBankPopup = void 0;
var popup_1 = require("constants/popup");
var BasePopup_1 = require("./BasePopup");
var Messenger_1 = require("common/Messenger");
var TokenBankPopup = /** @class */ (function (_super) {
    __extends(TokenBankPopup, _super);
    function TokenBankPopup(_a) {
        var url = _a.url, _b = _a.name, name = _b === void 0 ? "token-bank-popup" : _b;
        var _this = _super.call(this, { url: url, name: name }) || this;
        _this._setupKeepAlive = function () {
            _this._keepalive = setInterval(function () {
                var visible = _this.popupExists();
                if (visible === true) {
                    return;
                }
                _this._dispatchKeepAlive(visible);
                if (!visible) {
                    _this._popup = null;
                    clearInterval(_this._keepalive);
                }
            }, 500);
        };
        _this._dispatchKeepAlive = function (visible) {
            var messenger = new Messenger_1.Messenger({
                source: window,
                destination: _this._getWindowSource(window.parent),
            });
            messenger.dispatchMessage({
                type: popup_1.POPUP_EVENTS.BANK_POPUP_VISIBILITY_CHANGE,
                visible: visible,
            });
        };
        _this._bindEvents = function () {
            var messenger = new Messenger_1.Messenger({
                source: _this._getWindowSource(window.parent),
                destination: _this._popup,
            });
            messenger.onMessage(popup_1.POPUP_EVENTS.BANK_POPUP_CLOSE, function () {
                var _a;
                clearInterval(_this._keepalive);
                (_a = _this._popup) === null || _a === void 0 ? void 0 : _a.close();
            }, true);
        };
        _this._getWindowSource = function (targetWindow) {
            if (targetWindow && targetWindow[0] instanceof Window) {
                return targetWindow[0];
            }
            else {
                return targetWindow;
            }
        };
        _this._setupKeepAlive();
        _this._bindEvents();
        return _this;
    }
    return TokenBankPopup;
}(BasePopup_1.BasePopup));
exports.TokenBankPopup = TokenBankPopup;
