"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOKEN_SVG_ICON = exports.BUTTON_EVENTS = exports.DESKTOP_TYPES = exports.BUTTON_TYPES = exports.DEFAULT_BUTTON_LABEL = void 0;
exports.DEFAULT_BUTTON_LABEL = 'Quick Checkout';
exports.BUTTON_TYPES = {
    BUTTON_IFRAME: 'IFRAME'
};
exports.DESKTOP_TYPES = {
    REDIRECT: 'REDIRECT',
    MODAL_IFRAME: 'MODAL_IFRAME'
};
exports.BUTTON_EVENTS = {
    BUTTON_CLICK: 'BUTTON_PRESS',
    SET_BUTTON_STYLE: 'SET_BUTTON_STYLE',
    SET_BUTTON_ENABLED: 'SET_BUTTON_ENABLED'
};
exports.TOKEN_SVG_ICON = '<svg version="1.0" xmlns="http://www.w3.org/2000/svg"\n' +
    ' width="35" height="35" viewBox="0 0 395.000000 394.000000"\n' +
    ' class="tokenEnablerButton-svgIcon">\n' +
    '\n' +
    '<g transform="translate(0.000000,394.000000) scale(0.100000,-0.100000)"\n' +
    'fill="{color}" stroke="none">\n' +
    '<path d="M1577 2924 c-165 -40 -331 -173 -406 -326 -173 -350 -14 -745 357\n' +
    '-886 59 -23 83 -26 197 -26 144 -1 187 9 306 71 132 68 245 200 297 346 33 96\n' +
    '41 251 18 352 -54 227 -235 411 -462 469 -76 20 -228 20 -307 0z m315 -124\n' +
    'c197 -74 325 -248 336 -456 6 -109 -7 -172 -54 -270 -113 -231 -386 -339 -623\n' +
    '-245 -187 75 -321 275 -321 481 0 231 167 449 386 505 80 20 202 13 276 -15z"/>\n' +
    '<path d="M140 2735 l0 -55 180 0 180 0 0 -425 0 -425 65 0 65 0 2 423 3 422\n' +
    '178 3 177 2 0 55 0 55 -425 0 -425 0 0 -55z"/>\n' +
    '<path d="M2580 2310 l0 -480 65 0 65 0 0 220 0 220 154 0 154 0 154 -217 153\n' +
    '-218 73 -3 c39 -2 72 0 72 4 0 3 -15 27 -33 53 -218 302 -307 433 -300 444 4\n' +
    '7 78 110 165 229 87 120 158 220 158 223 0 3 -33 5 -73 5 l-74 0 -142 -200\n' +
    '-143 -200 -159 0 -159 0 0 200 0 200 -65 0 -65 0 0 -480z"/>\n' +
    '<path d="M0 1410 l0 -60 323 2 322 3 0 55 0 55 -322 3 -323 2 0 -60z"/>\n' +
    '<path d="M900 990 l0 -480 65 0 65 0 0 421 0 420 27 -3 c29 -3 68 -56 463\n' +
    '-617 l155 -220 128 0 127 -1 -2 478 -3 477 -60 0 -60 0 -3 -422 -2 -423 -25 0\n' +
    'c-20 0 -69 63 -327 425 l-302 425 -123 0 -123 0 0 -480z"/>\n' +
    '<path d="M0 1000 l0 -60 303 2 302 3 3 58 3 57 -306 0 -305 0 0 -60z"/>\n' +
    '<path d="M0 560 l0 -60 330 0 330 0 0 60 0 60 -330 0 -330 0 0 -60z"/>\n' +
    '</g>\n' +
    '</svg>\n';
